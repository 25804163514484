.subscribe__wrap {
    display: none;

    &.active {
        display: block;
    }
}

.banner__slider {
    .banner {
        display: flex;
    }
}

.banner__video-desc {
    object-fit: cover;
    display: block;
}

.banner__video-mob {
    object-fit: cover;
    display: none;
}

.subscribe__wrap {
    .subscribe__sb {
        margin-left: 2.5rem;
    }
}

.kv__slider .dots {
    .swiper-pagination-bullet {
        opacity: 0.5;
    }

    .swiper-pagination-bullet-active {
        opacity: 1;
    }
}

.head-line__slider .swiper-slide a {
    margin-bottom: 0;
    font-size: 0.875rem;
    line-height: 1.25rem;
    letter-spacing: 0.005em;
    white-space: nowrap;

    &:hover {
        color: unset;
    }
}

.collections .collections__wrap .desk {
    .col-banner {
        min-width: 100%;
    }
}

.information__text {
    a {
        text-decoration: underline;
    }

    ul {
        list-style-type: disc;
    }
}

.footer__menu-link-wrap {
    .footer__menu-link-dropdown {
        display: none;
    }
}

.product__feedback-block .info .top {
    justify-content: space-between;

    .top-block {
        display: flex;
        align-items: center;
    }
}

.all-filters-sizes .form-checkbox .label-dot {
    &.label-long {
        width: 4.75rem;
    }
}

.collapse__menu-kv h3 {
    color: #fff;
}

.stock-info-body {
    margin-top: 15px;
}

// .stocks__block {
//     height: 15rem;
// }

.stocks-page .collapse__menu-kv h3 {
    color: #fff;
}

.card__basket span.basket-size {
    &.label-long {
        width: 4.75rem;
    }
}

.collapse__menu-subtitle {
    margin-top: 10px;
    color: #fff;
}

.share__block {
    position: relative;

    .information__collapse {
        position: absolute;
        z-index: 5;
        bottom: -0.625rem;
        left: 20%;
        padding: 0.75rem;
        background: #fff;
        box-shadow: 0 0.625rem 1.875rem 0 rgba(38, 36, 34, 0.08);
        border-radius: 0.5rem;
        transform: translate(-50%, 100%);

        .social {
            flex-direction: column;

            li {
                padding: 0.5rem;

                a {
                    font-size: 1rem;
                    line-height: 1.5rem;
                    letter-spacing: 0.0002em;
                    white-space: nowrap;

                    .soc {
                        margin-right: 0.5rem;
                        width: 1.5rem;
                        height: 1.5rem;
                        background-size: contain;
                    }
                }
            }
        }
    }
}

.site-title {
    display: flex;
    align-items: flex-start;

    .site-title__h {
        margin-bottom: 0;
        font-size: 3rem;
        line-height: 3.25rem;
        letter-spacing: -0.02em;
    }
}

.product__kv-info-colors {
    &.block-fade {
        &::after {
            content: "";
            position: absolute;
            top: 0;
            right: 0;
            width: 7rem;
            height: 100%;
            background: linear-gradient(-90deg, #d9d9d9 0%, rgba(217, 217, 217, 0) 100%);
            border-radius: 0.5rem;
            z-index: 1;
            pointer-events: none;
        }
    }
}

.product__feedback-block {
    background: #f8f5f1;
    &.feedback-note {
        background: #fff;
    }
}

.now-buy__slider {
    position: relative;
}

.contacts-map {
    width: 100%;
}

.kv__wrap {
    .banner__text {
        color: #fff;
    }
}

.product__feedback-block {
    background: #f8f5f1;

    &.feedback-note {
        background: #fff;
    }
}

.now-buy__slider {
    position: relative;
}

// .modal-stocks__head .info h3 {
//     width: 40rem;
// }

// .modal-stocks__head img {
//     width: 10rem;
//     height: 4.5rem;
// }

.modal-stocks__body {
    table {
        max-width: 100%;
    }
}

.swiper-pagination-bullet-active .circle-origin {
    animation: progress 0s;
}

.simplebar-track.simplebar-vertical,
.simplebar-track.simplebar-horizontal {
    width: 8.5px !important;
    right: -5px;
    opacity: 0.3;
}
.simplebar-mask {
    right: 12px !important;
}

.collapse__menu-wrap {
    .scrollbar-inner {
        width: 100%;
        overflow-x: hidden;
    }
}

.collapse__menu-wrap .simple__bar {
    width: 100%;
}

.modal-feedback__product .img .image {
    display: none;

    &.active {
        display: block;
    }
}

.modal-review__send {
    .form-text {
        text-align: center;
    }
}

.modal-review .modal-body {
    width: 38.5rem;

    .stars {
        .title {
            margin-left: 0.75rem;
        }
    }
    .add-photo {
        position: absolute;
        width: 100%;
        height: 100%;
        opacity: 0;
    }
}

.modal-review .modal-content {
    width: 38.5rem;
    background: rgba(255, 255, 255, 0.95);
}

.modal-review__send {
    .modal-feedback__title {
        justify-content: flex-end;
    }
    .form-btns {
        margin-top: 30px;
        justify-content: center;
    }
}

.reviewImagesPopup .modal-content {
    z-index: 10;
    .modal-header {
        justify-content: end;
    }
    .modal-body {
        .swiper-button-prev,
        .swiper-button-next {
            color: #000;
        }
    }
}

.product__kv-info-sizes {
    .label-long {
        width: 4.75rem;
    }
}

.product__feedback {
    .left {
        height: auto;
    }
}

.all-filters__height {
    height: auto;
}

.form-selector--colors {
    .form-selector--list {
        height: auto;
    }
}

.share__block .information__collapse .social li .btn-ico {
    font-size: 1rem;
    letter-spacing: 0.0002em;
    line-height: 1.5rem;
    white-space: nowrap;
    .soc {
        background-size: contain;
        height: 1.5rem;
        margin-right: 0.5rem;
        width: 1.5rem;
    }
    .soc-text {
        display: flex;
        align-items: center;
    }
}

.t-info {
    color: #fff !important;
}

.shops .banner {
    height: calc(100vh - 5.5rem);
}

.shops__map .form-selector--list {
    height: auto;
}

.progress-circleSl {
    .circle-origin {
        stroke-dasharray: 350px;
        stroke-dashoffset: 350px;
        transition: stroke-dashoffset 0s linear;
        stroke-width: 0;
    }
}

.kv__slider-dots {
    .swiper-pagination-bullet-active {
        .circle-origin {
            stroke-width: 3;
        }
    }
}

.product__tabs-cont .desc__block {
    word-wrap: break-word;
}

// @media (max-width: 991px) {
//     .kv__slider-dots {
//         display: none;
//     }
// }

// @media (min-width: 992px) {
//     .kv__slider-dots {
//         display: block;
//     }
// }

.collections__banner .banner {
    height: calc(100vh - 5.5rem);
    &:hover {
        .banner__text {
            color: #fff;
        }
    }
}

.kv__slider .slide-wrap {
    height: calc(100vh - 5.5rem);
}

.modal--points .close {
    background: #000;
    color: #fff;

    &::before {
        opacity: 0;
    }

    &::after {
        opacity: 1;
    }
}

.modal-fashion .modal-basket__product-info .wrap .left .modal-basket__product-title {
    display: block;
    display: -webkit-box;
    position: relative;
    margin-bottom: 0.25rem;
    height: 3rem;
    font-size: 1rem;
    line-height: 1.5rem;
    letter-spacing: 0.002em;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    text-overflow: ellipsis;
    overflow: hidden;
    margin-bottom: 2rem;
    height: 3.5rem;
    font-size: 1.125rem;
    line-height: 1.75rem;
}

.product-tab-landings {
    .landing-page {
        margin-top: 5rem;
    }
}

.blog__aside-banner {
    height: 26rem;
}

.product .product__page img {
    width: unset;
    display: unset;
}

.product-tab-landings .information__link {
    margin-top: 1.5rem;
    text-align: center;
    display: block;
}

.product__page__landing .blur {
    position: relative;
}

.product__page__landing .blur::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: -3px;
    right: 0;
    height: 50px;
    background: linear-gradient(to bottom, transparent, rgba(255, 255, 255, 0.8));
    pointer-events: none;
}

.product__kv-info-sizes {
    &.inv {
        a {
            background: var(--c-main);
            color: #fff;

            &:hover {
                background: #fff;
                color: var(--c-main);
            }
        }

        a.disabled {
            color: #d9d9d9;
            border: 0.0625rem solid #d9d9d9;
            opacity: 1;
            pointer-events: none;
            background: unset;

            &:hover {
                background: unset;
                color: #d9d9d9;
            }
        }
    }
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.product__kv-all-image {
    display: none; // кнопка весь образ под слайдером
}

.product__kv-slider-inner {
    width: 100%;
}

.custom-zoom-full {
    height: 100% !important;
}

.custom-zoom-full img {
    height: 100% !important;
}

.product-kv-slideVideo {
    height: 100%;
    object-fit: cover;
}

.product__kv-slider-add {
    position: absolute !important;
}

.product__kv-slider-main .slick-track {
    margin-left: 10rem;
}

.product__kv-slider-main .slick-slide {
    padding: 0 2.65px;
}

.product__kv-slider-main .slick-list {
    margin: 0 -2.65px;
}

.product__kv-slider-add .slick-slide {
    padding: 0 2.5px;
}

.product__kv-slider-add .slick-list {
    margin: 0 -2.5px;
}


.modal-product-slider{
    overflow: hidden;
}

.slick-vertical .slick-slide {
    display: block;
}

.modal-product__slider-main .slick-vertical .slick-list {
    height: 100%;
    // overflow-y: scroll;
}

.no-scroll {
    overflow: hidden;
}

.modal-product__slider-main div{
    height: auto;
}

.modal-product__slider-main{
    overflow-y: scroll;
}

.modal-product__slider-add{
        margin-left: 3.25rem;
        margin-right: 3.25rem;
}

.modal-product__slider-main .slide-container{
    width: 100%;
    img{
        width: 100%;
    }
}

.modal-fashion .modal-dialog{
        max-width: 40rem;
}

.modal-fashion .modal-basket__product-info .wrap .left{
        width: 22rem;
}

.modal-basket__product-info .wrap .right{
        flex-shrink: 0;
}

.modal-fashion__imgs{
    overflow-y: hidden;
    .img{
        height: 100%;
        img{
            height: 100%;
            object-fit: cover;
        }
    }
}

.l-fl-center{
    display: flex;
    justify-content: center;
}

.col-20{
        flex: 0 0 auto;
        width: 20%;
}

.choose__slider-main {
    .swiper-wrapper {
        transition: 0 !important;
    }

    .swiper-slide {
        user-select: none;
        -webkit-user-drag: none;
        -webkit-tap-highlight-color: transparent;
    }

    .swiper-slide:focus {
        outline: none;
    }
}

.text-page__main-banner{
position: unset;
}

.product__feedback-block .info .top :last-child{
    margin-left: unset;
}

.all-filters .body{
    overflow-y: scroll;
}

.basket__promocode{
    margin-bottom: 1.25rem;
}